import './styles/styles.sass';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from './utils/ScrollToTop'

import Routing from './utils/Routing';

export default function App () {
  return (
    <>
        <div className="App">
          <Router>
            <ScrollToTop>
              <Routing />
            </ScrollToTop> 
          </Router>
        </div>
    </>
    
  );
}