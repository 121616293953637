import { Route, Routes, useLocation } from "react-router-dom";

import ComingSoonContent from "../views/ComingSoonContent";
import GeneralContent from "../views/GeneralContent/GeneralContent";

export default function Routing() {
  const location = useLocation();

  return (
    <Routes location={location} key={location.pathname}>
      <Route exact path="/" element={<GeneralContent />}>
        <Route exact path="/" element={<ComingSoonContent />} />
      </Route>
    </Routes>
  );
}
