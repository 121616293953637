import '../../styles/styles.sass';
// import { Link } from 'react-router-dom';
import React, { useState} from 'react'
import MailchimpSubscribe from "react-mailchimp-subscribe"
import { FormNewsletter } from "../../components/forms/FormNewsletter"

export default function ComingSoonContent() {
    // const ref = useRef();

    // Form Newsletter Mailchimp URL
    const url = "https://mumdus.us18.list-manage.com/subscribe/post?u=082c3319d4b785b939a563167&amp;id=c199828f24&amp;f_id=00311ee7f0";

    //////////////////////// Handling window size & resize ///////////////////////////////////////
    // const [isMobile, setMobile] = useState(window.innerWidth < 600);

    // const updateMedia = () => {
    //     setMobile(window.innerWidth < 600);
    // };

    // useEffect(() => {
    //     window.addEventListener("resize", updateMedia);
    //     return () => window.removeEventListener("resize", updateMedia);
    // }, [])


    //Handling Form
    const [isForm, setIsForm] = useState(false)


    // useEffect(() => {
        
    // }, [isMobile]);

    return (
        <>
            <div className="coming-soon__wrapper">
                <div className="coming-soon__logo">
                    <img src="/images/mumdus/mumdus_logo.svg" alt="" />
                    <img src="/images/mumdus/mumdus_logo.svg" alt="" />
                    <img src="/images/mumdus/mumdus_logo.svg" alt="" />
                    <img src="/images/mumdus/mumdus_logo.svg" alt="" />
                    <img src="/images/mumdus/mumdus_logo.svg" alt="" />
                </div>
                <h1 className={isForm ? 'coming-soon__title active' : 'coming-soon__title'}>Coming soon</h1>

                <div className={isForm ? 'coming-soon__form__wrapper active' : 'coming-soon__form__wrapper'}>
                    {isForm ? (
                        <>
                            <div className="form__header">
                                <h5>Access Mumdus</h5>
                                <button className="button button--hide-form" aria-label="Hide Form" onClick={()=> setIsForm(!isForm)}>
                                    <img src="/images/icons/icon_close.svg" alt="" />
                                </button>
                            </div>

                            <MailchimpSubscribe
                                url={url}
                                render={({ subscribe, status, successMessage, errorMessage }) => (
                                    <FormNewsletter
                                        successMessage={'Thanks for subscribing!'}
                                        buttonClass=""
                                        buttonText={"Subscribe"}
                                    />
                                )}
                            />
                        </>
                    ) : (
                        <button className="button button--show-form" onClick={()=> setIsForm(!isForm)}>
                            Access Mumdus
                        </button>
                    )}
                </div>
            </div>
        </>
    )
}