import React, { useEffect } from 'react'

export const FormNewsletter = (props) => {
    useEffect(() => {
        const js = `
            function ml_webform_success_4153099() {
                document.querySelector('.row-success').style.display = "block";
                document.getElementById('form-email').value = "";
                setTimeout(() => {
                    document.querySelector('.row-success').style.display = "none";
                }, 5000)
            };
        `;
        const script = document.createElement("script");
        const scriptText = document.createTextNode(js);
        script.appendChild(scriptText);
        document.body.appendChild(script);

        const script2 = document.createElement("script");
        script2.src = "https://groot.mailerlite.com/js/w/webforms.min.js?v300817f630ad0e957914d0b28a2b6d78";
        document.body.appendChild(script2);
    }, [])

    return (
        <div id="mlb2-4153099" className="ml-form-embedContainer ml-subscribe-form ml-subscribe-form-4153099">
            <div className="ml-form-align-center ">
                <div className="ml-form-embedWrapper embedForm">
                    <div className="ml-form-embedBody ml-form-embedBodyHorizontal row-form">
                        <form className="ml-block-form form form--newsletter" action="https://assets.mailerlite.com/jsonp/165031/forms/83273790095624085/subscribe" data-code="" method="post" target="_blank">
                            <div className="ml-field-group ml-field-email ml-validate-email ml-validate-required">
                                <input type="email" className={`form-control ${props.inputClass}`} id="form-email" data-inputmask="" name="fields[email]" placeholder="example@email.com" autoComplete="email" />
                                <span className="form__message form__message--error">This field is required</span> 
                            </div>

                            <div className="ml-button-horizontal primary">
                                <button type="submit" className={`${props.buttonClass} primary button`}>{props.buttonText}</button>
                                <button disabled="disabled" style={{display: 'none'}} type="button" className="loading">
                                    <div className="ml-form-embedSubmitLoad"></div>
                                    <span className="sr-only">Processing...</span>
                                </button>
                            </div>

                            <input type="hidden" name="ml-submit" value="1" />
                            <input type="hidden" name="anticsrf" value="true" />
                        </form>
                    </div>

                    <div className="ml-form-successBody row-success" style={{display: 'none'}}>
                        <div className="ml-form-successContent">
                        <span className="title--heading--xs">{props.successMessage}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


    // <form className="form--newsletter" onSubmit={(e) => handleSubmit(e)}>
    //   <input type="email" name="email" className="email__field" placeholder="example@email.com" onChange={setEmail} />
    //   {customErr && (
    //         <div className="form__message form__message--error">This field is required.</div>
    //     )}
    //   <button type="submit" className="button button--primary no-link submit__button" id="submit-newsletter" dangerouslySetInnerHTML={{ __html: buttonText }} />
    //   {status === "sending" && <div className="form__message form__message--processing">Processing...</div>}
    //   {status === "error" && ( <div className="form__message form__message--error" dangerouslySetInnerHTML={{ __html: errorMessage }} /> )}
    //   {status === "success" && ( <div className="form__message form__message--success" dangerouslySetInnerHTML={{ __html: successMessage }} /> )}
    // </form>
